import React, {Component} from 'react'
import Layout from 'components/Layout'
import {Container, Col, Row} from 'reactstrap'
import BoohBannerMob from 'images/booh-sub-576.jpg'
import BoohBannerTablet from 'images/booh-sub-991.jpg'
import BoohBannerDesk from 'images/booh-sub-1920.jpg'
import YouTube from "react-youtube"
import TourList from "components/Tours/USTourList"
import styled from "styled-components"
import {media} from "utils/Media"
import play from "images/icons/play.svg"
import placeholder from "images/trailer-placeholder.jpg"
import NewLogo from 'images/new-logo.png'





const Play = styled.div`
    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("${play}");
        background-repeat: no-repeat;
        background-size: contain;
        height: 60px;
        width: 60px;
	    	cursor: pointer;
        @media ${media.md} {
            height: 80px;
            width: 80px;
        }
        @media ${media.lg} {
            height: 110px;
            width: 110px;
        }
    }
    &:hover {
        &:after {
            background-color: red;
        }
    }
    `
const StyledContainer = styled(Container)`
.img {
    width: 70%;
    margin: 2rem 0;
    max-width: 350px;
}
.button {
    margin: 1rem 0;
    min-width: 200px;
    max-width :250px;
    background: ${props => props.theme.colors.yellow};
    border: solid 2px ${props => props.theme.colors.yellow};
    transition: all .25s ease-in-out;
    span {
        transition: all .25s ease-in-out;
    }
    &:hover, &:active {
        background: transparent;
        span {
            color: #fff;
        }
    }
}
h2 {
    text-transform: uppercase;
    margin: .5rem 0;
    @media ${media.md} {
            font-size: 3.5rem;
        }
}
.box {
    max-width: 850px;
    border: solid 3px #fff;
    margin: 1rem auto 1.5rem auto;
    padding: 1.5rem;
    h4 {
        font-size: 2.25rem;
        @media ${media.md} {
            font-size: 3.25rem;
        }
    }
    p {
        strong {
            font-size: 1.25rem;
            font-weight: 700;
        }
    }
}
`
const Placeholder = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url("${placeholder}");
    background-size: cover;
    background-position: center;
    cursor: pointer;
`


const headData = {
    title: 'Bat Out Of Hell!  - US tour homepage',
    description: 'he official site for the US Tour of Bat Out of Hell! The Musical based on the songs of Jim Steinman and Meat Loaf',
};

class IndexPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            popUp: false,
            placeholder: true,
            event: ""
        }

        this.onReady = this.onReady.bind(this)
        this.play = this.play.bind(this)
        this.showPlaceholder = this.showPlaceholder.bind(this)
    }

    onReady(event) {
        this.setState({
            player: event
        })
    }

    play() {
        this.state.player.target.playVideo()
        this.setState({
            placeholder: false
        })
    }
    toggleModal = (e) => {
        this.setState({
            // popUp: !this.state.popUp
            popUp: false
            
        });
    };
    showPlaceholder() {
        this.setState({
            placeholder: true
        })
    }

    render() {
    
        const opts = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                rel: 0,
                modestbranding: 1
            }
        }


        return (
            <>

                <Layout
                    headData={headData}
                    navGlobal={true}
                    path="/us-tour/"
                    us
                >
                    <div className="page bg--black">

                        <div>
                            <img src={BoohBannerMob} alt="Bat Out Of Hell!" className="img-fluid w-100 d-sm-none"/>
                            <img src={BoohBannerTablet} alt="Bat Out Of Hell!"
                                 className="img-fluid w-100 d-none d-sm-block d-lg-none"/>
                            <img src={BoohBannerDesk} alt="Bat Out Of Hell!" className="img-fluid w-100 d-none d-lg-block"/>
                        </div>

                        {/* <Row>
                            <TourList/>
                        </Row> */}

                        <Row>
                            <StyledContainer className="px-0 pt-3 py-md-5 text-center">
                                <Row>
                                    <Col xs={12}>
                                        <div className="col-xs-12 text-center home">
                                            <img className="img" src={NewLogo} />
                                            {/* <img className="img" src={Logo} /> */}
                                            
                                            <a className="d-block" href='https://batoutofhellvegas.com/ticket-info/' target="_blank"> 
                                                <button className="button btn">
                                                    <span>BOOK NOW</span>
                                                </button>
                                            </a>
                                            <h1 className="mb-0">BAT OUT OF HELL! LAS VEGAS RESIDENCY AT PARIS HOTEL &amp; CASINO</h1>
                                        </div>
                                        <div className="col-sm-12 col-md-9 mx-auto text-center">
                                            <br/>
                                            <p>Bringing to life the legendary anthems of <strong>Jim Steinman</strong> <strong>&amp;</strong> <strong>Meat Loaf</strong>, this critically-acclaimed production uniquely combines the magic and excitement of a musical with the immense energy of rock &lsquo;n&rsquo; roll.</p>
<p><br />Join us for an extravagant night at Paris Theatre  to witness a love story centered around two forever young teens.</p>
<p>This award-winning wild child musical thunders through powerhouse hits including&nbsp;<strong>I<em>&rsquo;d Do Anything For Love</em></strong> (But I Won&rsquo;t Do That), <strong><em>Paradise By The Dashboard Light</em></strong>, <strong><em>Two Out Of Three Ain&rsquo;t Bad</em></strong>, <strong><em>Dead Ringer For Love&nbsp;and&nbsp;Bat Out of Hell!</em></strong>, in an exciting over the top theatrical spectacle unlike any other.</p>
                                            <br/>
                                        </div>
                                    </Col>

                                    <Col sm={9} className="mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            {this.state.placeholder &&
                                            <>
                                                <Play onClick={this.play}/>
                                                <Placeholder src={placeholder} alt="Watch trailer"
                                                             title="Bat Out of Hell! The Musical – Official Trailer"
                                                             onClick={this.play}/>
                                            </>
                                            }
                                            <YouTube
                                                videoId="FXdKsR4tSes"
                                                opts={opts}
                                                onReady={this.onReady}
                                                onEnd={this.showPlaceholder}
                                                className="embed-responsive-item"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </StyledContainer>
                        </Row>

                    </div>
                    {/* <SignupModal popUp={this.state.popUp} toggleModal={this.toggleModal} /> */}
                </Layout>

            </>
        );

    }
}

export default IndexPage